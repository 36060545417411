@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  border: 1px solid #e9e9e9;
  border-radius: 20px;
  padding: 10px;
  transition: all ease 0.5s;

  @media (min-width:1600px) {
    padding: 20px;
  }

  &:hover {
    transform: scale(1.02);
    transition: all ease 0.5s;
    /* box-shadow: var(--boxShadowListingCard); */
  }
}

.listingImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  background: var(--matterColorNegative);
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  @apply --marketplaceH3FontStyles;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

/* new classes for listing card long */

.reviews {
  color: var(--marketplaceColorDark);
  background: #FF790026 0% 0% no-repeat padding-box;
  border-radius: 6px;
  margin: 0;
  max-height: 1.8pc;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font: normal normal bold 13px/18px Nunito;
}

.trips {
  margin: 0;
  font: normal normal normal 14px/19px Nunito;
  letter-spacing: 0px;
  color: #656363;
  margin-left: 15px;
}

.starIcon {
  color: var(--marketplaceColorDark);
  transform: scale(0.7);
}

.listingIconWrapper {
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.listingIcon {
  height: 1pc;
  width: auto;
  object-fit: contain;
  margin-right: 2px;
}

.listingPrice {
  text-align: center;
  font: normal normal 600 15px/24px Nunito;
  letter-spacing: 0px;
  color: var(--matterColorDark);
  margin-bottom: 20px;

  @media (--viewportSmall) {
    font: normal normal 600 18px/24px Nunito;
  }

  &>span {
    font: normal normal normal 18px/24px Nunito;
    letter-spacing: 0px;
    color: #A8A8A8;
  }
}

.rentNowButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 23px;
  color: var(--marketplaceColorDark);
  padding: 0 10px;
  cursor: pointer;
  width: calc(100% - 30px - 10px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 14px/20px Poppins;
  letter-spacing: 0px;

  @media (--viewportMedium) {
    width: calc(100% - 40px - 10px);
  }

  @media (min-width:1600px) {
    width: calc(100% - 45px - 10px);
    font: normal normal 600 16px/25px Poppins;
  }

  &:hover {
    color: white;
    background-color: var(--marketplaceColorDark);
  }
}

.favouritesButtonIcon {
  color: var(--marketplaceColorDark);
  height: 1.2pc;
}

.favouritesButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 111px;
  height: 30px;
  width: 30px;

  @media (--viewportMedium) {
    height: 40px;
    width: 40px;
  }

  @media (min-width:1600px) {
    height: 45px;
    width: 45px;
  }
}

.listingImage {
  border-radius: 15px;
}

.listingTitle {
  width: 100%;
  text-align: center;
  font: normal normal 800 14px/18px Nunito;
  letter-spacing: 0px;
  color: var(--marketplaceColor);
  margin: 0 0 12px 0;

  @media (min-width:580px) {
    font: normal normal 800 15px/18px Nunito;
  }

  @media (min-width:767px) {
    font: normal normal 800 16px/20px Nunito;
  }

  @media (min-width:1600px) {
    font: normal normal 800 18px/24px Nunito;
  }
}

.favButtonWrapper {
  margin: auto 0;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 10px;
  height: 30px;
  width: 30px;

  @media (--viewportMedium) {
    height: 40px;
    width: 40px;
  }

  @media (min-width:1600px) {
    height: 45px;
    width: 45px;
  }
}

.favButtonNotSelected {
  width: 18px !important;
  cursor: pointer;
  display: block;
  color: var(--marketplaceColorDark);

  &:hover {
    transform: scale(1.1);
    filter: invert(43%) sepia(100%) saturate(762%) hue-rotate(357deg) brightness(102%) contrast(109%);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}

.favButtonSelected {
  cursor: pointer;
  display: block;
  filter: invert(43%) sepia(100%) saturate(762%) hue-rotate(357deg) brightness(102%) contrast(109%);

  &:hover {
    transform: scale(1.1);
  }
}

.bottomWrapper {
  display: flex;
  width: 100%;
}

.cardBlock {
  & .cardImg {
    & .listingImage {}
  }

  & .reviewSec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }
}
