@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

@media (max-width: 767px) {
  .sideNav {
    display: none;
  }
}

.staticPageWrapper {
  width: calc(100% - 158px);
  margin: 0px 0px 0px 158px;
  background-color: white;
  padding: 0px 24px 30px 24px;

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
  }

  @media screen and (max-width: 767px) {
    margin: 0px auto !important;
    width: 100% !important;
  }

  @media (--viewportMedium) {
    padding: 80px 24px 30px;
  }

  @media (min-width: 1500px) {
    padding: 92px 34px 30px;
  }
}

.noWrapper {
  width: calc(100% - 158px);
  margin: 0px 0px 0px 158px;
  background-color: white;
  padding: 0px 24px 30px 24px;

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
  }

  @media screen and (max-width: 767px) {
    margin: 0px auto !important;
    width: 100% !important;
  }

  @media (--viewportMedium) {
    padding: 120px 24px 30px;
  }

  @media (min-width: 1500px) {
    padding: 140px 34px 30px;
  }
}

.listingPageWrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}

.sectionShare {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  background-color: white;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }

  @media (min-width: 768px) {
    border-radius: 20px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }

  @media (min-width: 768px) {
    border-radius: 20px;
  }
}

.actionBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  top: -13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--marketplaceColor);
  z-index: 1;
  padding: 0.7rem;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
  @media (--viewportMedium) {
    position: absolute;
    top: -5%;
    left: 13px;
  }
  @media (--viewportLarge) {
    position: absolute;
    top: -8%;
    left: 13px;
  }
  @media (--viewportXXLarge) {
    position: absolute;
    top: -7%;
    left: 13px;
  }
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }

  @media (min-width: 768px) {
    border-radius: 20px;
  }
}

.viewPhotos {
  display: none;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  display: flex;
  padding: 0px;
  flex-direction: column;

  @media (--viewportMedium) {
    padding: 40px 0px 0px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.mainContent {
  background-color: var(--matterColorLight);
  width: 100%;

  @media (--viewportLarge) {
    width: calc(100% - 350px);
    padding-right: 20px;
  }

  @media (min-width: 1600px) {
    width: calc(100% - 450px);
    padding-right: 30px;
  }

  & .recommendSection {
    border-top: 1px solid #ebebeb;
    padding: 30px 0 0px;

    & .recommendSectionTitle {
      font: normal normal 600 18px/28px Poppins;
      letter-spacing: 0px;
      color: #000000;
      margin: 0 0 10px 0;
    }

    & .recommendSectionSubtitle {
      color: var(--matterColor);
      font: normal normal normal 16px/22px Nunito;
      letter-spacing: 0px;
    }

    & .recommendedListingsWrapper {
      margin: 25px 0;
      display: flex;
      flex-wrap: wrap;

      & > .listingCard {
        width: calc(100% / 3 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;

        @media (min-width: 1024px) {
          width: calc(100% / 2 - 15px);

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:nth-child(3n) {
            margin-right: 15px;
          }
        }

        @media (min-width: 1280px) {
          width: calc(100% / 3 - 15px);

          &:nth-child(3n) {
            margin-right: 0;
          }

          &:nth-child(2n) {
            margin-right: 15px;
          }
        }

        @media (max-width: 414px) {
          width: calc(100% / 2 - 10px);
          margin-right: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.bookingPanel {
  width: 100%;

  @media (--viewportLarge) {
    max-width: 350px;
    display: block;
  }

  @media (min-width: 1600px) {
    max-width: 450px;
  }
}

.sectionAvatar {
  display: none;
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }

  @media only screen and (max-width: 900px) {
    width: auto;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  margin-bottom: 45px;
}

.reviewsHeading {
  display: flex;
  margin: 20px 0;
  position: relative;
  z-index: 0;
  flex-direction: column;

  @media (--viewportMedium) {
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    flex-direction: row;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-bottom: 2px dashed #e9e9e9;
    content: '';
    width: 100%;
    z-index: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & > h2 {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: var(--matterColorLight);
    width: 100%;
    margin: 0;
    font: normal normal 600 17px/45px Poppins;
    letter-spacing: 0px;
    color: var(--matterColorDark);

    @media (min-width: 414px) {
      padding: 0px 20px 0 0;
      width: auto;
    }

    @media (--viewportMedium) {
      font: normal normal 600 15px/28px Poppins;
    }

    @media (--viewportLarge) {
      padding: 0px 30px 0 0;
      font: normal normal 600 18px/28px Poppins;
    }
  }

  & .socialMediaIcons {
    display: flex;
    align-items: center;
    background-color: var(--matterColorLight);
    position: relative;
    z-index: 1;

    @media (--viewportMedium) {
      padding-left: 20px;
    }

    & .shareText {
      font: normal normal normal 14px/23px Nunito;
      letter-spacing: 0px;
      color: #919191;
      margin-right: 10px;
    }

    & .share {
      display: flex !important;
      align-items: flex-end !important;
      justify-content: flex-end !important;
      margin-left: 50px;

      @media screen and (max-width: 768px) {
        margin-left: 15px;
      }
    }

    & .socialShare {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100% !important;
    }

    & .socialIcon {
      cursor: pointer;
      width: 20px;
      margin: 0 4px;

      &:hover {
        transform: scale(1.02);
        color: var(--marketplaceColor);
      }
    }
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* user section styles */

.contentWrapper {
  width: 100%;
  /* @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
  margin-bottom: 0;
  margin-top: 2pc;

  @media (max-width: 768px){
    margin-bottom: 6pc;
    padding: 0 1pc;
  } */
}

.gridWrapper {
  height: 100%;
  width: 100%;

  & .userProfileDiv {
    display: flex;
    padding: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #17020212;
    border-radius: 20px;

    & .avatar {
      width: 100px;
      height: 100px;

      @media (--viewportLargeWithPaddings) {
        width: 160px;
        height: 160px;
      }
    }

    & .userInfoWrapper {
      display: flex;
      width: calc(100% - 160px);
      padding-left: 20px;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 300px) {
        flex-direction: column;
      }

      & .infoWrapper {
        display: flex;
        flex-direction: column;
        width: 35%;
        justify-content: flex-start;

        @media (max-width: 900px) {
          width: 100%;
        }

        & .userName {
          margin: 0;
          font: normal normal bold 25px/28px Poppins;
          letter-spacing: 0px;
          color: var(--matterColorDark);
          margin: 0 0 10px 0;

          & .joined {
            font: normal normal normal 14px/19px Nunito;
            letter-spacing: 0px;
            color: #919191;
          }
        }
      }

      & .reviewsWrapper {
        display: flex;
        border-bottom: dashed 1px var(--matterBorderColor);
        width: 50%;
        margin: 0;
        margin: 0px;
        max-height: 2pc;
        font: normal normal normal 14px/19px Nunito;
        letter-spacing: 0px;
        color: #1a1a1a;
        letter-spacing: 0;
        align-items: center;
        align-content: center;

        & .reviewsNumber {
          background: #ff790026 0% 0% no-repeat padding-box;
          border-radius: 5px;
          color: var(--marketplaceColorDark);
          font-size: 15px;
          letter-spacing: 0;
          padding: 0 10px;
          text-align: center;
          margin: 0;
          margin-right: 10px;
          line-height: 25px;
        }
      }
    }
  }
}

.abstractBackground {
  width: 100%;
  height: 20pc;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  position: absolute;
  z-index: 6;
  top: 0;
}

.listingInfoWrapper {
  height: auto;
  width: 100%;
  position: relative;
  border-radius: 20px;
  padding-top: 30px;

  @media (min-width: 1440px) {
    padding-top: 60px;
  }
}

.listingInfoCardWrapper {
  min-height: 20vh;
  width: calc(100% - 30px);
  position: relative;
  z-index: 9;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  background-color: white;
  padding: 20px;

  @media (min-width: 1440px) {
    width: calc(100% - 60px);
  }

  & .productDetailsBlock {
    display: flex;

    & .productImg {
      width: 40%;
      align-self: start;
      position: relative;

      & .homeDeliveryLabel {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background-color: var(--marketplaceColorDark);
        color: var(--matterColorLight);
        border-radius: 6px;
        padding: 4px 10px;
        font-size: 12px;
        line-height: 100%;

        & > svg {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      & .mainImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
        border: 1px solid #e2e2e2;
        cursor: pointer;
        transition: all ease 0.5s;

        &:hover {
          transform: scale(1.01);
          transition: all ease 0.5s;
        }
      }
    }

    & .productInfo {
      width: 60%;
      padding-left: 15px;

      @media (min-width: 1440px) {
        padding-left: 30px;
      }

      & .productOtherImages {
        margin-bottom: 10px;

        & .smallImage {
          height: 100%;
          width: 25%;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
          border: 1px solid #e2e2e2;
          max-height: 4pc;
          padding: 0 2px;
          cursor: pointer;

          &:hover {
            transform: scale(1.02);
          }
        }
      }

      & .productOtherInfo {
        margin-bottom: 20px;

        & .listingTitle {
          width: 100%;
          font: normal normal bold 18px/22px Poppins;
          letter-spacing: 0px;
          color: var(--matterColorDark);
          margin: 0 0 10px 0;

          @media (--viewportLarge) {
            font: normal normal bold 22px/26px Poppins;
          }

          @media (min-width: 1280px) {
            font: normal normal bold 26px/36px Poppins;
          }

          @media (min-width: 1440px) {
            font: normal normal bold 30px/46px Poppins;
            margin: 0 0 15px 0;
          }
        }

        & .listingPrice {
          width: 100%;
          font: normal normal 600 16px/24px Nunito;
          letter-spacing: 0px;
          color: var(--matterColorDark);

          @media (min-width: 1440px) {
            font: normal normal 600 18px/24px Nunito;
          }

          & > span {
            font: normal normal normal 16px/24px Nunito;
            letter-spacing: 0px;
            color: #afafaf;

            @media (min-width: 1440px) {
              font: normal normal normal 18px/24px Nunito;
            }

            & .separator {
              color: #e9e9e9;
              margin: 0px;
            }
          }
        }
      }

      & .productRating {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > * {
          margin-bottom: 10px;
        }

        & .favButtonWrapper {
        }

        & .favButtonNotSelected {
          cursor: pointer;
          display: block;

          &:hover {
            filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%)
              contrast(108%);
          }
        }

        & .favButtonNotSelected {
          cursor: pointer;
          display: block;
          color: var(--marketplaceColorDark);

          &:hover {
            filter: invert(43%) sepia(100%) saturate(762%) hue-rotate(357deg) brightness(102%)
              contrast(109%);
          }
        }

        & .favButtonSelected {
          cursor: pointer;
          display: block;
          filter: invert(43%) sepia(100%) saturate(762%) hue-rotate(357deg) brightness(102%)
            contrast(109%);
        }

        & .reviews {
          color: var(--marketplaceColorDark);
          background: #ff790026 0% 0% no-repeat padding-box;
          border-radius: 6px;
          max-height: 1.8pc;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          font: normal normal bold 13px/18px Nunito;
          letter-spacing: 0px;
          color: var(--marketplaceColorDark);

          & .starIcon {
            color: var(--marketplaceColorDark);
            transform: scale(0.7);
          }
        }

        & .trips {
          font: normal normal normal 14px/19px Nunito;
          letter-spacing: 0px;
          color: #656363;
          max-height: 2pc;
          margin-left: 10px;
        }

        & .deliveryTime {
          font: normal normal normal 14px/19px Nunito;
          letter-spacing: 0px;
          color: #656363;
          max-height: 2pc;
          margin-left: 10px;
        }

        & .instantBooking {
          background: #28d57926 0% 0% no-repeat padding-box;
          border-radius: 5px;
          font: normal normal bold 15px/20px Nunito;
          letter-spacing: 0px;
          color: #175d2e;
          padding: 2px 8px;
          display: inline-flex;
          align-items: center;
          margin-left: 10px;

          & .checkIcon {
            width: 16px;
            margin-right: 5px;
            color: #175d2e;
          }
        }
      }
    }
  }

  & .productModalDetails {
    display: flex;
    margin: 30px 0 0px;
    flex-wrap: wrap;

    @media (min-width: 1280px) {
      flex-wrap: nowrap;
      margin: 30px 0 25px;
    }

    & .subInfoWrapper {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      margin-bottom: 20px;

      @media (min-width: 1280px) {
        width: calc(100% / 6);
        margin-bottom: 0;
      }

      @media (min-width: 1440px) {
        width: calc(100% / 6);
      }

      & .subInfoWrapperTop {
        font: normal normal 600 14px/19px Nunito;
        letter-spacing: 0px;
        color: #000000;
        margin: 0 0 5px 0;
      }

      & .subInfoWrapperBottom {
        font: normal normal bold 15px/20px Nunito;
        letter-spacing: 0px;
        color: #000000;
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
}

.recommendSection {
  border-top: 1px solid #ebebeb;
  padding: 30px 0 0px;

  & .recommendSectionTitle {
    font: normal normal 600 18px/28px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin: 0 0 10px 0;
  }

  & .recommendSectionSubtitle {
    color: var(--matterColor);
    font: normal normal normal 16px/22px Nunito;
    letter-spacing: 0px;
  }

  & .recommendedListingsWrapper {
    margin: 25px 0;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}

.favButtonWrapper {
  margin: auto 0;
  border: 1px solid var(--marketplaceColorDark);
  border-radius: 111px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 10px;

  & > svg {
    width: 20px;
    height: 20px;
  }
}

.mobileContainer {
  & .mobile {
    background-image: linear-gradient(to right, #080808, #14213d);
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-self: center !important;
    margin-bottom: 30px;

    @media screen and (min-width: 768px) {
      display: none;
    }

    & .mobileText {
      font: normal normal bold 28px/38px Nunito;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  & .gridWrapper {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--matterBorderColor);

    & .userProfileDiv {
      display: flex;
      padding: 0px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: none;
      border-radius: 0px;

      & .avatar {
        border: none;
        width: 60px;
        height: 60px;
      }

      & .userInfoWrapper {
        display: flex;
        width: calc(100% - 60px);
        padding-left: 10px;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 480px) {
          flex-direction: column;
        }

        & .infoWrapper {
          display: flex;
          flex-direction: column;
          width: 35%;
          justify-content: flex-start;

          @media (max-width: 900px) {
            width: 100%;
          }

          & .userName {
            margin: 0;
            font: normal normal bold 18px/24px Poppins;
            letter-spacing: 0px;
            color: var(--matterColorDark);
            margin: 0 0 5px 0;

            & .joined {
              font: normal normal normal 14px/19px Nunito;
              letter-spacing: 0px;
              color: #919191;
            }
          }
        }

        & .reviewsWrapper {
          display: flex;
          border-bottom: dashed 1px var(--matterBorderColor);
          width: 50%;
          margin: 0;
          margin: 0px;
          max-height: 2pc;
          font: normal normal normal 14px/19px Nunito;
          letter-spacing: 0px;
          color: #1a1a1a;
          letter-spacing: 0;
          align-items: center;
          align-content: center;

          @media (max-width: 480px) {
            width: 100%;
            margin: 5px;
          }

          & .reviewsNumber {
            background: #ff790026 0% 0% no-repeat padding-box;
            border-radius: 5px;
            color: var(--marketplaceColorDark);
            font-size: 15px;
            letter-spacing: 0;
            padding: 0 10px;
            text-align: center;
            margin: 0;
            margin-right: 10px;
            line-height: 25px;
          }
        }
      }
    }
  }

  & .productDetailsBlock {
    width: 100%;

    & .productImg {
      mix-blend-mode: multiply;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 40px;

      & > img {
        width: 100%;
      }
    }

    & .productInfo {
      & .productOtherImages {
        margin-bottom: 10px;

        & .smallImage {
          height: 100%;
          width: calc(100% / 5);
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
          border: 1px solid #e2e2e2;
          max-height: 4pc;
          padding: 0 2px;
          cursor: pointer;

          &:hover {
            transform: scale(1.02);
          }
        }
      }

      & .productOtherInfo {
        & .listingTitle {
          font: normal normal bold 20px/30px Poppins;
          letter-spacing: 0px;
          color: #000000;
          margin: 0 0 15px 0;
          text-align: center;
        }

        & .changeVehicle {
          display: flex;
          align-items: center;

          & .listingPrice {
            width: 50%;
            font: normal normal 600 16px/22px Nunito;
            letter-spacing: 0px;
            color: #000000;

            & > span {
              font: normal normal normal 16px/22px Nunito;
              letter-spacing: 0px;
              color: #a8a8a8;

              & .separator {
                margin: 0;
              }
            }
          }

          & .changeBtnSec {
            width: 50%;
            text-align: right;

            & > button {
              font: normal normal bold 15px/27px Nunito;
              letter-spacing: 0px;
              color: #ff7900;
              padding: 7px 20px;
              background: #ff790000 0% 0% no-repeat padding-box;
              border: 1px dashed #ff7900;
              border-radius: 100px;
            }
          }
        }

        & .productRating {
          display: flex;
          align-items: center;
          margin: 10px 0;
          border-bottom: solid 1px var(--matterBorderColor);
          padding-bottom: 20px;

          & .productRatingLeft {
            width: 60%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            & > * {
              margin-bottom: 5px;
            }

            & .reviews {
              color: var(--marketplaceColorDark);
              background: #ff790026 0% 0% no-repeat padding-box;
              border-radius: 6px;
              max-height: 1.8pc;
              padding: 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              align-content: center;
              font: normal normal bold 13px/18px Nunito;
              letter-spacing: 0px;
              color: var(--marketplaceColorDark);

              & .starIcon {
                color: var(--marketplaceColorDark);
                transform: scale(0.7);
              }
            }

            & .trips {
              font: normal normal normal 14px/19px Nunito;
              letter-spacing: 0px;
              color: #656363;
              max-height: 2pc;
              margin-left: 1pc;
              margin-right: 1pc;
              margin-top: -2px;
            }

            & .instantBooking {
              background: #28d57926 0% 0% no-repeat padding-box;
              border-radius: 5px;
              font: normal normal bold 12px/18px Nunito;
              letter-spacing: 0px;
              color: #175d2e;
              padding: 2px 10px;

              @media (min-width: 414px) {
                padding: 2px 15px;
                font: normal normal bold 15px/20px Nunito;
              }

              & .checkIcon {
                width: 16px;
                margin-right: 5px;
                color: #175d2e;

                @media (min-width: 414px) {
                  margin-right: 10px;
                }
              }
            }
          }

          & .productRatingRight {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & .socialMediaIcons {
              display: flex;
              align-items: center;
              background-color: var(--matterColorLight);
              position: relative;
              z-index: 1;

              @media (--viewportMedium) {
                padding-left: 20px;
              }

              & .shareText {
                font: normal normal normal 14px/23px Nunito;
                letter-spacing: 0px;
                color: #919191;
                margin-right: 10px;
              }

              & .share {
                display: flex !important;
                align-items: flex-end !important;
                justify-content: flex-end !important;
                margin-left: 50px;

                @media screen and (max-width: 768px) {
                  margin-left: 15px;
                }
              }

              & .socialShare {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                width: 100% !important;
              }

              & .socialIcon {
                cursor: pointer;
                width: 20px;
                margin: 0 4px;

                &:hover {
                  transform: scale(1.02);
                  color: var(--marketplaceColor);
                }
              }
            }
          }
        }

        & .productModalDetails {
          display: flex;
          margin: 30px 0 10px;
          flex-wrap: wrap;
          border-bottom: solid 1px var(--matterBorderColor);

          @media (min-width: 1280px) {
            flex-wrap: nowrap;
            margin: 30px 0 25px;
          }

          & .subInfoWrapper {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3);
            margin-bottom: 20px;

            @media (min-width: 1280px) {
              width: calc(100% / 6);
              margin-bottom: 0;
            }

            @media (min-width: 1440px) {
              width: calc(100% / 7);
            }

            & .subInfoWrapperTop {
              font: normal normal 600 14px/19px Nunito;
              letter-spacing: 0px;
              color: #000000;
              margin: 0 0 5px 0;
            }

            & .subInfoWrapperBottom {
              font: normal normal bold 15px/20px Nunito;
              letter-spacing: 0px;
              color: #000000;
              margin: 0;
              text-transform: capitalize;
            }
          }
        }

        @media (max-width: 768px) {
          .rentNowSec {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }

        & .rentNowSec {
          display: flex;
          position: fixed;
          bottom: 75px; 
          left: 0;
          right: 0;
          background-color: white; 
          padding: 10px;
          /* z-index: 1000; */
          border: none;
      

          & .bookButton {
            font: normal normal bold 16px/20px Nunito;
            letter-spacing: 0px;
            color: #ffffff;
            background: #ff7900 0% 0% no-repeat padding-box;
            border-radius: 100px;
            padding: 12px 30px;
            border: none;
            flex-grow: 1;
          }

          & .favButtonWrapper {
            margin: auto 0;
            border: 1px solid var(--marketplaceColorDark);
            border-radius: 111px;
            height: 45px;
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-right: 0;
            margin-left: 15px;

            & > svg {
              width: 20px;
              height: 20px;
            }
          }

          & .favButtonNotSelected {
            cursor: pointer;
            display: block;

            &:hover {
              filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%)
                contrast(108%);
            }
          }

          & .favButtonNotSelected {
            cursor: pointer;
            display: block;
            color: var(--marketplaceColorDark);

            &:hover {
              filter: invert(43%) sepia(100%) saturate(762%) hue-rotate(357deg) brightness(102%)
                contrast(109%);
            }
          }

          & .favButtonSelected {
            cursor: pointer;
            display: block;
            filter: invert(43%) sepia(100%) saturate(762%) hue-rotate(357deg) brightness(102%)
              contrast(109%);
          }
        }
      }
    }
  }

  & .recommendSection {
    border-top: 1px solid #ebebeb;
    padding: 30px 0 0px;

    & .recommendSectionTitle {
      font: normal normal 600 18px/28px Poppins;
      letter-spacing: 0px;
      color: #000000;
      margin: 0 0 10px 0;
    }

    & .recommendSectionSubtitle {
      color: var(--matterColor);
      font: normal normal normal 16px/22px Nunito;
      letter-spacing: 0px;
    }

    & .recommendedListingsWrapper {
      margin: 25px 0;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;

        & > div {
          max-width: 100%;
          width: 100%;
        }
      }

      @media (max-width: 580px) {
        grid-template-columns: repeat(2, 1fr);
      }

      & > .listingCard {
        width: calc(100% / 2 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;

        @media (max-width: 414px) {
          width: calc(100% / 2 - 10px);
          margin-right: 10px;
          margin-bottom: 15px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

.modalContent {
  & > h3 {
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: none;
  }

  & > h4 {
    font: normal normal bold 16px/22px Nunito;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
  }

  & > p {
    margin: 0 0 15px 0;
    font: normal normal normal 16px/22px Nunito;
    letter-spacing: 0px;
    color: #7a7a7a;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.infoSections {
  & .infoSection {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 25px 0;
    padding: 30px;
  }

  & .infoSectionTitle {
    text-align: left;
    letter-spacing: 0px;
    color: var(--matterColorDark);
    font: normal normal 500 21px/31px Poppins;
    margin: 0 0 20px 0;
  }

  & .infoSectionBody {
    letter-spacing: 0px;
    color: var(--matterColor);
    font: normal normal normal 16px/22px Nunito;
    margin: 0 0 20px 0;
  }

  & .infoSectionLearnMore {
    color: var(--marketplaceColorDark);
    cursor: pointer;

    & > svg {
      margin-left: 15px;
      width: 12px;
    }
  }
}

.keyRuleContainer {
  display: flex;
  margin-top: 30px;

  & .keyRules {
    margin: 10px 0;
    width: calc(100% / 2);
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }

    & > h2 {
      font: normal normal 600 20px/24px Poppins;
      margin: 0 0 10px 0;
      color: var(--matterColorDark);
    }

    & > ul {
      list-style-type: decimal;
      list-style-position: inside;

      & > li {
        font: normal normal normal 16px/20px Nunito;
        letter-spacing: 0px;
        color: #919191;
        margin: 0 0 8px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    & > p {
      font: normal normal normal 16px/20px Nunito;
      letter-spacing: 0px;
      color: #919191;
      margin: 0 0 8px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
